@media screen and (max-width: 1025px) {
	.main-header {
		.menu-icon {
			display: block;

			&__line {
				animation: closedMid 0.8s backwards;
				animation-direction: reverse;

				&::before {
					animation: closedTop 0.8s backwards;
					animation-direction: reverse;
				}
				&::after {
					animation: closedBtm 0.8s backwards;
					animation-direction: reverse;
				}
			}
		}
	.nav-links {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		padding: 10rem 0;
		width: 100vw;
		height: 100vh;
		font-size: 1.5rem;
		background: $dark-green;
		transition: 
			opacity .5s .2s,
			clip-path .5s .2s;
		clip-path: circle(200px at top right);

		li {
			font-size: 1.25em;
		}
		.nav-link {
			opacity: 0;
			transform: translateX(100%);
			width: 100%;
			text-align: center;

		a{
			display: block;
			padding: 2rem 0;
			padding-bottom: 2rem;
			color: $text-light;
			border-bottom: none;      

		}
		a:hover {
      		background: rgba(255,255,255, 0.2);
      		color: $text-light;
			padding-bottom: 2rem;      		
      		border-bottom: none;      
      	}
	  }
	}
	.menu-btn:checked ~ .nav-links {
		opacity: 1;
		clip-path: circle(100% at center);
	
		.nav-link {
			opacity: 1;
			transform: translateX(0);
			transition:
				opacity .6s ease-in-out .4s;
		}

  }
  	.menu-btn:checked ~ .menu-icon {
  		border-radius: 50%;
  		animation: pulse 1s;

		.menu-icon__line {
			background: $light-green;
			animation: openMid 0.8s forwards;

			&::before {
				background: $light-green;
				animation: openTop 0.8s forwards;
			}

			&::after {
				background: $light-green;
				animation: openBtm 0.8s forwards;
			}

		}
	}
 }

 .hero h1 {
 	font-size: 2rem;
 }
}

@media screen and (max-width: 472px) {
	.main-header {
	height: 160px;
	}
}