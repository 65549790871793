@import './_constants';

$vert-spacing: variableSize(40, 80);

.expect-container {
  padding-left: $horizontal-padding;
  padding-right: $horizontal-padding;
  padding-top: 6vh;
  max-width: 1300px;
  margin: 0 auto;
}
.howWork-img {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  color: #fff;

  h2 {
  position: relative;
  font-size: 3rem;
  color: $text-dark;
  text-transform: none;
  text-align: left;
  border-radius: 20px 0;
  padding-bottom: 2rem;
  }

  p {
  padding-top: 10vh;
  position: relative;
  color: $text-black;
  text-transform: initial;
  text-align: center;
  font-weight: 300;
  }

}

.therapy-works-container {
  .content-container {
      padding-bottom: 5vh !important;
      }
}

.howWork-text {
  padding-bottom: 5vh;
    
  h3 {
  margin-top: 0;
  color: $light-green;
  }
    p,
  li {
    color: $text-dark;
    letter-spacing: initial;
    line-height: 1.25rem;
  }

  p {
   line-height: 1.5rem;
   margin-bottom: .75rem;
}
}


.howWork-text-1 {
  padding-bottom: 5vh;
    
  h3 {
  margin-top: 0;
  color: $light-tan;
  }
    p,
  li {
    color: $text-dark;
    letter-spacing: initial;
  }

  p {
   line-height: 1.5rem;
   margin-bottom: .75rem;
}
}



.work {
  p,
  b,
  li,
  i {
    color: $text-light;
  }
}

.work-description {
  margin: 0 auto $vert-spacing;
  max-width: 800px;

  h1 {
    text-align: center;
  }
}

.work-additional {
  margin: $vert-spacing auto 0;
  max-width: 800px;
}

.citation {
  font-size: 0.8rem;
  padding-left: 25px !important;
  text-indent: -25px;

  &:last-of-type {
    margin-bottom: 0;
  }

  a{
    color: $blue;
  }
}


.track-kern-1 {
  letter-spacing: 0px;
  word-spacing: 1.5px;
}

.track-kern-2 {
  letter-spacing: -.25px;
  word-spacing: -.5px;

}

.track-kern-3 {
  letter-spacing: -.25px;
  word-spacing: -.5px;
}

.theories {
  display: flex;
  border: 2px solid $light-tan;
  border-radius: 3px;
  background-color: $lightest-tan;
  padding: 4rem 2rem;

  h4 {
    color: $dark-green;
    margin-bottom: $spacing;
    text-align: center;
    font-weight: 600;
  }

  p {
  position: relative;
  color: $text-dark;
  text-transform: initial;
  text-align: center;
  line-height: 1.5rem;
  }
}

.theory {
  flex: 1;
  margin-left: $spacing * 3;
  text-align: center;

  p {
    margin: 0;

  }

  &:last-of-type {
    margin-right: $spacing;
  }
  &:first-of-type {
    margin-left: $spacing;
  }
}

  .theory-icon {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 1rem;
  }
  
  .circle-color-1 {
    background-color: $dark-tan;
  }
  .circle-color-2 {
    background-color: $light-green;
  }
  .circle-color-3 {
      background-color: $blue;
  }


@media only screen and (max-width: 1000px) {
  .howWork-text {
    p,
  li {
    margin: auto 0 0.75rem 0;
    padding: 0;
  }
}

.howWork-img {
  h2{
  padding-bottom: 0rem;
  }
}

  .howWork-text-1 {
    padding-bottom: 0;
}



  .theories {
    flex-direction: column;
  }

  .theory {
    max-width: 700px;
    margin: 0 auto $vert-spacing;

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
