@import './constants';

$height: 500px;

  h3{
    color: $light-green;
    letter-spacing: initial;
    line-height: initial;
    margin: 1.5rem 0 1.5rem 0;
  }

.office {
  display: flex;
  justify-content: space-between;

}

.office-container {
  .content-container {
      padding-bottom: 0 !important;
      }
}

.office-1 {
  background-image: url(../images/office-1.jpg);
  background-size: cover;
  background-position-x: 75%;
  background-position-y: 75%;
  height: $height;
  width: calc(55% - 1rem);
}

.office-2 {
  background-image: url(../images/office-2.jpg);
  background-size: cover;
  background-position-x: 75%;
  background-position-y: 75%;
  height: $height;
  width: calc(45% - 1rem);
}

.office-1-img,
.office-2-img {
  display: none;

  &:first-of-type {
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .office {
    flex-direction: column;
  }

  .office-1,
  .office-2 {
    display: none;
  }

  .office-1-img,
  .office-2-img {
    display: block;
  }
}
