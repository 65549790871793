@import './constants';

.main-header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 160px;
  padding: 0 $horizontal-padding;
  color: $text-light;
  background-color: $light-tan;
  z-index: 1;
  top: 0;
  width: 100%;
  transition: 0.2s;

  a {
    text-decoration: none;
    color: $text-dark;
    text-transform: none;
    font-weight: 400;
    
  }

  .logo {
  a{
    font-size: 2rem;
        font-weight: inherit;
    transition: 0s;
    letter-spacing: initial;

    .clinpsych  {
        padding-top: 0.4rem;
        font-size: 1.25rem;
        font-weight: 400;
        color: $text-dark !important;
        transition: 0s;
        letter-spacing: initial;

    }

  }
  }

  .nav-links {
    display: flex;
    list-style: none;

    .nav-link {
      transition:
        opacity .4s ease-in-out .2s;

      a{
      margin: .2rem;
      padding: 1rem .5rem;
      }
      a:hover {
        border-bottom: 1px solid $text-dark;      
        padding-bottom: 3px;

      }
      a:active {
        border-bottom: 1px solid $text-dark;      
        padding-bottom: 3px;     
    
            }
    }
  }

  .menu-icon {
    position: relative;
    padding: 26px 10px;
    cursor: pointer;
    z-index: 1;
    display: none;

    &__line {
      display: block;
      position: relative;
      background: $dark-green;
      height: 2px;
      width: 20px;
      border-radius: 4px;
      transition: background .8s ease;

      &::before, &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background: $dark-green;
        transition: background .8s ease;
      }

      &::before {
        transform: translateY(-5px)
      }
      &::after {
        transform: translateY(5px)
      }
    }
  }
  .menu-btn {
    position: absolute;
    top: -100px;

    &:focus ~ .menu-icon {
      .menu-icon__line {
        &::before {
          transform: translateY(-7px);
        }
        &::after {
          transform: translateY(7px);
        }
      }
    }
  }
}



.titles {
  display: flex;
  align-items: right;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: right;

  h1 {
    font-size: variableSize(24, 45);
    font-weight: bold;
  }

  h2 {
    font-size: variableSize(20, 28);
  }
}



