@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

$text-dark: #373328;
$text-light: #E3DFDC;
$text-black: #0C0B09;

$lightest-tan: #F6F5F4;
$light-tan: #E3DFDC;
$dark-tan: #CBC1BC;
$blue: #6E9092;
$light-green: #615336;
$dark-green: #373328;

$spacing: 1.5rem;

$minWidth: 400;
$maxWidth: 1440;
@function variableSize($min, $max) {
  @return calc(
    #{$min}px +
      ((100vw - #{$minWidth}px) / #{(($maxWidth - $minWidth) / ($max - $min))})
  );
}

$vertical-padding: variableSize(50, 100);
$horizontal-padding: variableSize(22, 60);

$minWidth: 400;
$maxWidth: 1440;
@function variableSize($min, $max) {
  @return calc(
    #{$min}px +
      ((100vw - #{$minWidth}px) / #{(($maxWidth - $minWidth) / ($max - $min))})
  );
}

