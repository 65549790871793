@import './_constants';

$vert-spacing: variableSize(40, 80);

p,
li,
h2 {
    color: $text-dark;
}

h4 {
  color: $blue;
}

.science-preamble {
  margin: 0 0 $spacing;
  max-width: 800px;

  h1 {
    text-align: center;
    color: $text-dark;
  }
}

.science-additional {
  margin: 0 0;
  max-width: 800px;
}

.science-lists {
  display: flex;

  h2 {
    color: $text-dark;
    margin-bottom: $spacing;
    text-align: center;
  }
}

.science-list {
  flex: 1;
  margin-left: $spacing * 3;

  p {
    margin: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .science-lists {
    flex-direction: column;
  }

  .science-list {
    max-width: 700px;
    margin: 0 auto;

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
