@import './constants';

.header-img {
  background-image: url(../images/forest.jpg);
  background-size: cover;
  background-position-x: 75%;
  background-position-y: 100%;
  height: 100%;
  max-width: 450px;
  width: 40%;
}


.howWork-img {
  background-image: url(../images/what-to-expect-4.jpeg);
  background-size: cover;
  background-position-x: 100%;
  background-position-y: 65%;
  height: 85vh;
  max-width: 100vw;
  width: 100%;
  }

.img-filter {
  background-color: rgba(55, 51, 40, 0.1);
  height: 100%;
  width: 100%;
}

.img-filter-hiw {
position: relative;
  background-color: rgba(55, 51, 40, 0);
  height: 100%;
  width: 100%;
}

.divider-img {
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
  height: variableSize(150, 400);
  width: 100%;
}

.divider-img-2 .contact-top {
  display: none;
}

.contact-right {
  background-image: url(../images/divider-img-3.jpeg);
  background-size: cover;
  background-position-x: 40%;
  background-position-y: 75%;
  height: $height;
}

.landscape-1 {
  background-image: url(../images/divider-img-1.jpeg);
  background-size: cover;
  background-position-x: 0%;
  background-position-y: 60%;
  height: 40vh;
  width: 100%;
}

.landscape-2 {
  background-image: url(../images/divider-img-2.jpeg);
  background-size: cover;
  background-position-x: 40%;
  background-position-y: 70%;
  height: 40vh;
  width: 100%;
  }

@media only screen and (max-width: 1025px) {
  .howWork-img {
    background-position-x: 0%;
    background-position-y: 0%;
    height: 55vh;
    max-height: 653px;
  }

  .divider-img-2 {
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
  height: variableSize(150, 400);
  width: 100%;
}

.contact-top {
  background-image: url(../images/divider-img-3.jpeg);
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
  height: 40vh;
  width: 100%;
}

.contact-right {
  display: none;
}
}

@media only screen and (max-width: 600px) {
  .header-img {
    height: 40%;
    max-width: 600px;
    width: 100vw;
  }
  .howWork-img {
    height: 85vh;
    background-position-x: 80%;
    background-position-y: 10%;
  }



}
