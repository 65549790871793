@import './_constants';


.shouldbe-container {
  .content-container {
      padding-bottom: 2.5vh !important;
    }  
}

.shouldbe {
  display: flex;
  padding-top: 5vw;

  p,
  li {
    color: $text-black;
  }

  p,
  li {
    letter-spacing: initial;
    line-height: 1.25rem;
  }

  p {
   line-height: 1.5rem;
  }

  li {
   margin: .5rem 0 .5rem 0;
  }

  h2 {
    color: $text-dark;
    font-size: 3rem;
    letter-spacing: initial;
    line-height: initial;
    margin: .75rem 0 ;
  }

  h3 {
      color: $light-green;
      margin: 0 0 1rem 0;
      strong {
      color: inherit;
      }
  }

  h4 {
    color: $text-dark;
    font-weight: 600;

  }
      &:first-of-type {
      margin-bottom: 0;
      padding-top: 0;
  }
}

.shouldbe-text-box {
display: flex;
  align-items: center;
}

.shouldbe-img-1 {
  width: 45vw;
  max-width: 590px;
  max-height: 400px;  
  height: 100vh;
  object-fit: cover;
  object-position: 0% 40%;
}

.shouldbe-img-2 {
  width: 45vw;
  max-width: 590px;
  max-height: 400px;  
  height: 100vh;
  object-fit: cover;
  object-position: 60% 70%;
}



.shouldbe-content {
  padding: 0 0 0 5vw;

  p{
    margin-bottom: .75rem;
  }
    h3 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      &:first-of-type {
        margin-top: 0;
      }
    }
      ul {
      padding-left: 3vw;
    }
  
    ul.special-focus {
    list-style-position: outside;
    }
}



.shouldbe-content-1 {
  align-items: center;
  padding: 0 5vw 0 2.5vw;
    
    ul {
      padding-left: 3vw;
    }
  
    ul.special-focus {
    list-style-position: outside;
    }

    .divider-line {
      display: none;
      margin: 1.5rem 0.75rem 0.75rem 0;
      position: relative;
      background: $blue;
      height: 2px;
      width: 20vw;
      border-radius: 4px;
    }
}

@media only screen and (max-width: 785px) {
  

  .shouldbe {
    flex-direction: column;
      h3 {
        margin: 1.5rem 0 .5rem 0;
      }
  }

  .shouldbe-content {
    padding: 0;

      ul {
      margin-left: 5vw;
    }
  
  ul.special-focus {
    list-style-position: outside;
  }
  }

.shouldbe-content-1 {
  padding: 0;
    
  ul {
      margin-left: 5vw;
    }
  
  ul.special-focus {
    list-style-position: outside;
  }
  
  .divider-line {
    display: block;
  }
}

  .shouldbe-img-container {
    display: inline-block;
    height: 60%;
    margin: 0 0 2rem;
    overflow: hidden;
    max-width: 100vw;
    width: 100%;
  }
    p {
    letter-spacing: initial;
    line-height: initial;
    margin: auto 0 auto 0;
  }

  .shouldbe-img-1 {
    width: 100vw;
  }
   .shouldbe-img-2 {
   margin-top: 40px;
    width: 100vw;
  }
}
