.faq-container {
  padding: $vertical-padding $horizontal-padding;
  max-width: 1300px;
  margin: 0 auto;


  h2{
    margin: 0rem 0 1.5rem 0;
  }
}

.faq-accordion {
  background-color: $light-tan;
  border-left: 2px solid #E3DFDC;
  border-right: 2px solid #E3DFDC;
  border-top: 2px solid #E3DFDC;
  border-radius: 3px 3px 3px 3px;
  border-bottom: 2px solid #E3DFDC;
  margin-top: 18px;
  color: $text-dark;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: .875rem;
  transition: 0.4s;
  letter-spacing: 1.5px;


  &:first-of-type {
  margin-top: 20px;
  }


  .faq-text {
    width: 80%;
        color: $text-dark;

  }
}

.active, .faq-accordion:hover {
  background-color: $light-tan;
}

.panel {
  padding: 0px 18px;
  margin: -3px 0px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-left: 2px solid #E3DFDC;
  border-right: 2px solid #E3DFDC;
  border-bottom: 2px solid #E3DFDC;
  border-radius: 3px 3px 3px 3px;

  p,
  li,
  i {
    color: $text-dark;
    strong {
      color: inherit;
      }
  }

  p:first-of-type {
    padding-top: 18px;
  }
  p:last-of-type {
    padding-bottom: 18px;
  }

  p,
  li {
    letter-spacing: initial;
    line-height: 1.3rem;
  }

  p {
   line-height: 1.5rem;
  }

      ul {
      margin-left: 3vw;
    }
        ul {
    list-style-position: outside;
    }
}


.faq-accordion:after {
  content: "+"; /* Unicode character for "plus" sign (+) */
  font-size: .875rem;
  color: $text-dark;
  font-weight: 500;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "-"; /* Unicode character for "minus" sign (-) */
  font-size: .875rem;
  color: $text-dark;
  font-weight: 500;
  float: right;
  margin-left: 5px;
}

@media only screen and (max-width: 785px) {
      ul {
      padding-left: 3vw;
    }
    .faq-accordion:after {
  display: none;
}

.active:after {
    display: none;
}
}
