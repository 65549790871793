.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  width: 100vw;
  bottom: 0;
  padding: 0 10vw;
  color: $text-light;
  background-color: $lightest-tan;
  text-align: center;
  justify-content: center;

  	.copyright-text {

    	p {
    		color: $text-dark;
  		}
  	}

}

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  width: 100vw;
  bottom: 0;
  padding: 0 $horizontal-padding;
  color: $text-light;
  background-color: $lightest-tan;
  text-align: center;
  justify-content: center;

  .nav-links {
    display: flex;
    list-style: none;

    .nav-link {
      transition:
        opacity .4s ease-in-out .2s;

      a{
      margin: .2rem 0;
      color: $blue;

        img {
            width: 184px;
            height: auto;
            }
      }
      a:hover {
      color: $light-tan;
      }
      a:active {
      color: $light-tan;
      }

    }
  }

}
