@import './_constants';

$vert-spacing: variableSize(40, 80);

.about {
  display: flex;
  margin-bottom: 0px;

  p,
  li {
    color: $text-dark;
  }

  p,
  li {
    letter-spacing: initial;
    line-height: 1.3rem;

  }

  h2 {
    color: $text-dark;
    font-size: 2.5rem;
    letter-spacing: initial;
    line-height: initial;
    margin: 40px 0 0 0;
    margin-bottom: 0.5rem;
  }

}


.about-text-box {
display: flex;
  align-items: baseline;
}
.about-img {
  width: 30vw;
  max-width: 590px;
  max-height: 400px;  
  object-fit: contain;
  object-position: top left;
}

.about-img-m {
  display: none;
}

.about-img-1 {
  width: 60vw; 
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: 50% 90%;
}



.about-content {
  padding: 0 0 0 5vw;
  p{
    margin-bottom: .75rem;
  }
  h3{
    margin: 2rem 0 1rem 0;
  }
  margin-top: 160px;
  padding-top: $horizontal-padding;
  padding-bottom: $horizontal-padding;
}

.about-content-1 {
  align-items: center;
  padding: 0 5vw 0 2.5vw;
    
    ul {
      padding-left: 3vw;
    }
  
    ul.special-focus {
    list-style-position: outside;
    }

    h3 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      &:first-of-type {
        margin-top: 0;
      }
    }
}


    .divider-line {
      display: none;
      margin: 1.5rem 0.75rem 0.75rem 0;
      position: relative;
      background: $blue;
      height: 2px;
      width: 20vw;
      border-radius: 4px;
    }





.about-infos {
  display: flex;
  border: 2px solid $light-tan;
  border-radius: 3px;
  padding: 2rem 2rem 4rem 2rem;
  background-color: $lightest-tan;

  
  h3 {
      color: $light-green;
      margin: 2rem 0 1rem 0 !important;
  }
  h4 {
    color: $dark-green;
    margin-bottom: $spacing;
    text-align: center;
    font-weight: 600;
  }

  p {
  position: relative;
  color: $text-dark;
  text-transform: initial;
  text-align: center;
  line-height: 1.5rem;
  }
}

.about-info {
  flex: 1;
  margin-left: 5vw;
  text-align: left;

  p {
    margin: 0;

  }

    p,
  li {
    color: $text-dark;
    letter-spacing: initial;
    line-height: 1.3rem;
  }

    p,
    li,
    h3 {
    text-align: left !important;
    }

      ul {
      padding-left: 3vw;
    }
  
    ul.special-focus {
    list-style-position: outside;
    }

  &:last-of-type {
    margin-right: 0;
  }

}











@media only screen and (max-width: 765px) {
  

  .about {
    flex-direction: column;
      h2 {
        margin: 0 0 0.5rem 0;
      }
      h3 {
        margin: 1.5rem 0 .5rem 0;
      }
  }

  .about-content {
    padding: 0;
    margin-top: 0px;
  }

.about-content-1 {
  padding: 0;
    
  ul {
      margin-left: 5vw;
    }
  
  ul.special-focus {
    list-style-position: outside;
  }
}

  .divider-line {
    display: block;
    margin-bottom: 1.25rem;
  }

  .about-img-container {
    display: inline-block;
    height: 60%;
    margin: 0 0 2rem;
    overflow: hidden;
    max-width: 100vw;
    width: 100%;
  }
    p {
    letter-spacing: initial;
    margin: auto 0 auto 0;
  }

  .about-img {
    display: none;
  }

   .about-img-1 {
   display: none;
  }


  .about-img-m {
    display: block;
    padding-top: 20px;
    padding-bottom: $vertical-padding;
    width: 100vw;
    max-width: 100%;
    object-fit: contain;
    object-position: top left;
  }

  .about-infos {
    flex-direction: column;
  }

  .about-info {
    max-width: 700px;
    margin: 0 auto 0;

  ul {
      margin-left: 5vw;
    }
  
  ul.special-focus {
    list-style-position: outside;
  }

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 5vw;
    }
  }
}

@media only screen and (max-width: 718px) {
  .about-infos {
    flex-direction: column;
  }
  .about-info:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}
}


