@import 'normalize.css';
@import './constants';

body {
  background: rgb(245, 241, 239);
  font-family: 'Open Sans', sans-serif;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $light-tan;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 1.5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1.5rem;
  font-weight: normal;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 1.3rem;
  font-weight: 400;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
  color: #6E9092;
  margin-bottom: 1.5rem;

}

p {
  margin: 0 0 0.3rem;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1.3rem !important;
  letter-spacing: normal;
}

li {
  font-weight: normal;
  font-size: .875rem;
}
// h2,
// h3,
// h4,
// h5,
// h6 {
//   color: $text-light;
// }

$text-spacing: 1.5rem;


b {
  display: block;
  margin-bottom: $text-spacing / 4;
}

.last-item {
  margin-bottom: 0;
}

.main-gradient {
  background: rgb(225, 217, 213);
  background: linear-gradient(
    130deg,
    rgba(225, 217, 213, 1) 0%,
    rgba(215, 204, 198, 1) 100%
  );
}

.white-bg {
  background: #fff;
}

.tan-bg {
  background: $lightest-tan;
}

.dark-green-bg {
  background: $dark-green;
}

.light-green-bg {
  background: $light-green;
}


.dark-gradient {
  background: rgb(212, 200, 180);
  background: linear-gradient(
    130deg,
    rgba(212, 200, 180, 1) 0%,
    rgba(191, 172, 150, 1) 100%
  );
}

.light-gradient {
  background: rgb(245, 241, 239);
  background: linear-gradient(
    130deg,
    rgba(245, 241, 239, 1) 0%,
    rgba(241, 237, 234, 1) 100%
  );
}

.white-bg {
  background: #fff;
}

.container {
  width: 80%;
  margin: 2rem auto;
  p{
  margin: 1rem 0;
  }
}

.content-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: $vertical-padding $horizontal-padding;
}

.left-content-container {
  max-width: none;
  margin: 0 0 0 0;
  padding: 0 $horizontal-padding 0 0;
}


.hero {
display: none;
}

@media only screen and (max-width: 765px) {


.left-content-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 $horizontal-padding 0 $horizontal-padding;

}

.hero {
  display: block;
  height: 70vh;
  background: url('../images/forest-original.jpg') no-repeat;
  background-position-x: 100%;
  background-position-y: 65%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
  /* margin: 0 $horizontal-padding; */
  color: #fff;

  h1 {
  position: relative;
  font-size: 3rem;
  color: $text-light;
  text-transform: uppercase;
  text-align: center;
  padding: 1.5rem;
  border-radius: 20px 0;
    line-height: normal !important;
  }

  p {
  padding-top: 1.5rem;
  position: relative;
    font-size: 2rem;
  color: $text-light;
  text-transform: initial;
  text-align: center;
  font-weight: 300;
  line-height: 2rem !important;
  }
}
}


