.player-container {
  //position: absolute;
  //bottom: 2em; 
  max-width: 50vw;
  width: 100%;
  margin-top: 1vh;
}

.player-title {
  color: $dark-green;
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 0vh;
}

// Overrides
.rhap_container {
  box-shadow: none !important;
  height: 6.5rem !important;
  background-color: rgba(0,0,0,0) !important;
  position: relative;
  padding: 10px 15px 10px 0 !important;
  z-index: 0;

  .autoplay {
  display: none !important;
  }
}


.rhap_progress-indicator,
.rhap_volume-indicator {
  box-shadow: none !important;
  opacity: 1 !important;
  background: $light-green !important;
}

.rhap_time {
  color: $light-green !important;
  font-size: 0.8rem !important;
}

.rhap_play-pause-button {

  height: 1.5em !important;
  width: 1.5em !important;
  margin: 3px -3px !important;

  svg {
    height: 90% !important;
    width: 90% !important;
  
    path {
      color: $dark-green !important;  
    }
  }
}

.rhap_additional-controls {
  display: none !important;
}

.rhap_volume-controls {
  display:none !important;
}

.rhap_rewind-button{
  display: none;
}  

.rhap_forward-button {
  display: none;
}




@media only screen and (max-width: 1025px) {

.player-container {
  max-width: 90vw;
  width:100%;
}
}
