@import './_constants';




.contact-columns {
  display: flex;
}

.contact-left-column {
  width: calc(33% - #{$spacing * 2});
}

.contact-right-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: $spacing * 3;
}

.contact-right-row {
  display: flex;
  margin-bottom: 5vh;

  h3{
  margin: 2rem 0 1rem 0;
  }
  span {
    flex: 1;
    padding-bottom: 5vh;

    &:last-of-type {
      margin-left: $spacing * 3;
    }
  }
    a {
  color: $blue;
  }
    a:hover {
  color: $light-green;
  }
  p, em {
  color: $text-black;
  line-height: 1.5rem;
  }

  img {
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
}
  ul {
    padding-left: 3vw;
  }
  li {
    letter-spacing: initial;
    color: #0C0B09;
    line-height: 1.3rem;
  }
}




  .phone-row {
    line-height: 1.5rem;
    height: 1.5rem;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .phone-row a {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
  }

    .phone-row img {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
  }

    .address-row {
    line-height: 3rem;
    height: 3rem;
    text-align: left;
  }

  .address-row a {
    line-height: 3rem;
    display: inline-block;
    vertical-align: middle;
  }

    .address-row img {
    line-height: 3rem;
    display: inline-block;
    vertical-align: middle;
  }



@media only screen and (max-width: 1000px) {
  .contact-left-column {
    width: 100%;
  }

  .contact-columns,
  .contact-right-row {
    flex-direction: column;
  }

  .contact-right-column,
  .contact-right-row span:last-of-type {
    margin-left: 0;
  }

  .contact-right-row {
    span {
    padding-bottom: 2vh;
    }

    ul {
    padding-left: 3vw;
    margin-left: 5vw;
  }
  li {
    letter-spacing: initial;
    color: #0C0B09;
  }
  }
}
